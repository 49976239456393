import * as React from "react";

const cloudinaryVideoUrl = `https://res.cloudinary.com/dpd64vzpx/video/upload`;

function Video({ src, videoLink, ...rest }) {
  const videoRef = React.useRef();

  // React.useEffect(() => {
  //   let options = {
  //     root: null,
  //     rootMargin: "0px",
  //     threshold: 0.05,
  //   };

  //   let observer = new IntersectionObserver((entries) => {
  //     const [entry] = entries;
  //     if (entry && videoRef.current) {
  //       entry.isIntersecting
  //         ? videoRef?.current.play()
  //         : videoRef?.current.pause();
  //     }
  //   }, options);

  //   observer.observe(videoRef.current);
  // }, []);

  return (
    <video
      ref={videoRef}
      autoPlay={true}
      src={`${cloudinaryVideoUrl}${src}`}
      loop
      playsInline
      muted
      {...rest}
    />
  );
}

function VideoLink({ href, text = "Watch", bg = "black", color = "white" }) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      style={{ backgroundColor: bg }}
    >
      <div className="mt-1">
        <span
          className="px-4 py-1.5 bg-black text-white font-sans"
          style={{ backgroundColor: bg, color, fontSize: "0.7rem" }}
        >
          {text}
        </span>
      </div>
    </a>
  );
}

export { Video, VideoLink };
