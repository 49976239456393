import * as React from "react";
import { Link, useParams } from "react-router-dom";

function usePage() {
  const params = useParams();
  return params.page || "entry";
}

const labelMap = {
  work: "info",
  entry: "info",
  info: "work",
};

const linkMap = {
  work: "/info",
  entry: "/info",
  info: "/",
};

function Header({ label, href = "/" }) {

  return (
    <div className="my-4 max-w-screen-lg mx-auto">
      <div
        className="px-4 md:text-2xl justify-between md:justify-center md:space-x-12 text-2xl flex mx-auto flex-wrap justify-start"
        // style={{ color: `rgb(164, 209, 255)` }}
      >
        <Link to={href}>
          <p className="font-serif">
            Stephen Smith{" "}
            {label && (
              <>
                (<span className="underline">{label}</span>)
              </>
            )}
          </p>
        </Link>
        <p className="font-serif">e: stephen.smitty@gmail.com</p>
      </div>
    </div>
  );
}

export { Header };
