import "lazysizes";
import "lazysizes/plugins/blur-up/ls.blur-up";

import * as React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { Enter } from "./pages/Enter";
import { Header } from "./components/Header";
import { Info } from "./pages/Info";
import { sections } from "./components/Sections";
import { preloadPage, Work } from "./pages/Work";

// TODOS
// https://github.com/aFarkas/lazysizes/blob/gh-pages/README.md
// better image and video loading strategies
// password configurations - think about how to do this

// swipe on mobile gallerys
// prevent overscrolling

// autoplay only when in window
// videos slow to load
// blue background for info page

// password page
// update files / figure out better CDN?

function AppContainer({ children }) {
  return <Router>{children}</Router>;
}

function AppRoutes() {
  const location = useLocation();
  const restrictHeight = location.pathname.includes("/info");

  return (
    <div
      className={`flex-1 relative ${
        restrictHeight ? "max-h-screen overflow-scroll" : ""
      }`}
    >
      <div className="flex-1 mx-auto max-w-screen-2xl">
        <Route path="/">
          <div className="relative">
            <Header label="info" href="/info" />
            <Work sections={sections} />

            <Route path="/info">
              <div
                className="fixed inset-0 overflow-scroll"
                style={{ backgroundColor: "rgb(211, 203, 247)" }}
              >
                <Header label="work" />
                <Info />
              </div>
            </Route>
          </div>
        </Route>
      </div>
    </div>
  );
}

const PW_KEY = "PW";
const PASSWORDS = ["195"];

preloadPage(0);

const isValidPassword = (pw) => pw && PASSWORDS.includes(pw);

function getUserIsAuthenticated() {
  const pw = sessionStorage.getItem(PW_KEY);
  return isValidPassword(pw);
}

function App() {
  const [userIsAuthenticated, setUserIsAuthenticated] = React.useState(() => {
    return getUserIsAuthenticated();
  });

  function onSubmit(inputValue) {
    if (isValidPassword(inputValue)) {
      sessionStorage.setItem(PW_KEY, inputValue);
      setUserIsAuthenticated(true);
    }
  }

  if (!userIsAuthenticated) {
    return <Enter onSubmit={onSubmit} />;
  }

  return (
    <AppContainer>
      <AppRoutes />
    </AppContainer>
  );
}

export default App;
