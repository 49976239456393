import * as React from 'react'

const cloudinaryImageUrl = `https://res.cloudinary.com/dpd64vzpx/image/upload`;

function getSrcsetForUrl(url = "") {
  const urls = [
    `${cloudinaryImageUrl}/w_20${url}`,
    `${cloudinaryImageUrl}/w_300,q_auto${url} 300w`,
    `${cloudinaryImageUrl}/w_600,q_auto${url} 600w`,
    `${cloudinaryImageUrl}/w_900,q_auto${url} 900w`,
    `${cloudinaryImageUrl}/w_1800,q_auto${url} 1200w`,
  ];

  return urls;
}



function Image({ src, className = "", style = {}, alt }) {
  if (!src) {
    return null;
  }

  const [placeholder, ...srcSet] = getSrcsetForUrl(src);

  return (
    <div className="mediabox max-w-screen-xl mx-auto w-full">
      <img
        style={style}
        className={`max-h-screen-lg mx-auto lazyload ${className} img`}
        // loading="lazy"
        data-lowsrc={placeholder}
        data-srcset={srcSet.join(",")}
        alt={alt}
      />
    </div>
  );
}


function Gif({ src = "", className = "", alt = "", loading = "lazy" }) {
  const [placeholder] = getSrcsetForUrl(src);

  return (
    <div className="mediabox">
      <img
        className={`mx-auto w-full ${className}`}
        loading={"eager"}
        data-sizes="auto"
        data-lowsrc={placeholder}
        data-srcset={``}
        src={`${cloudinaryImageUrl}/w_1200,q_auto${src}`}
        alt={alt}
      />
    </div>
  );
}

export { Image, Gif, getSrcsetForUrl }