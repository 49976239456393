import * as React from "react";

function Enter({ onSubmit }) {
  const [inputValue, setInputValue] = React.useState("");

  return (
    <div className='mx-auto'>
      <div className="my-4 max-w-screen-lg mx-auto">
        <div className="px-4 md:text-2xl justify-between md:justify-center md:space-x-12 text-2xl flex mx-auto flex-wrap justify-start">
          <p className="font-serif">Stephen Smith</p>
          <p className="font-serif">e: stephen.smitty@gmail.com</p>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="mt-24">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(inputValue);
            }}
          >
            <div className="flex items-center space-x-1">
              <input
                className="px-4 py-1.5 rounded-lg border border-black focus:outline-none"
                placeholder=""
                type="password"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />

              <button
                value="Submit"
                type="submit"
                className="py-1 px-4 rounded-lg focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-8 w-8"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export { Enter };
