import * as React from "react";

const pageUrls = [
  [
    `/v1612210891/100_j90gox.jpg`,
    `/v1612299687/401_k25zrv.jpg`,
    `/v1612210983/400_eqmbeu.mp4`,
    `/v1612210897/200_nv6zos.jpg`,
    `/v1612559359/1701_ir55ad.jpg`,
    `/v1612210961/500_a2rbe6.mp4`,
    `/v1612560023/1702_f7k0z5.jpg`,
    `/v1612560062/1702_vkvhbs.mp4`,
    `/v1612299614/1200_bou5ps.gif`,
  ],
  [
    `/v1612562623/2000_ljj5d0.jpg`,
    `/v1612562624/2001_hochwx.jpg`,
    `/v1612562623/2002_sf4e1t.jpg`,
    `/v1612299583/1100_euzhcn.jpg`,
    `/v1612299583/1101_yfljbj.jpg`,
    `/v1612299577/1102_pt7or2.jpg`,
    `/v1612299582/1103_ybxe2j.jpg`,
    `/v1612559103/1104_pensjw.jpg`,
    `/v1612729739/1105_xikfha.jpg`,

    `/v1612299634/700_deyygg.mp4`,
    `/v1612729546/301_wi6qhr.jpg`,
    `/v1612557730/300_id6bjt.mp4`,
  ],
  [
    `/v1612560735/1800_hahilo.jpg`,
    `/v1612560754/1800_rqusrv.mp4`,
    `/v1612561823/3000_t1wapf.mp4`,
    `/v1612561731/3000_k5rlzq.png`,
    `/v1612714521/3901_vshzul.jpg`,
    `/v1612715379/3900_jgvjae.mp4`,
    `/v1612715993/2900_ay6ehd.mp4`,
  ],

  [
    `/v1612714522/4200_vylezo.jpg`,
    `/v1612562845/2101_es8szh.jpg`,
    `/v1612562844/2100_qpppbv.jpg`,
    `/v1612562846/2102_qzytas.jpg`,
    `/v1612302457/800_qftxym.jpg`,
    `/v1612561191/1001_e949pb.mp4`,
    `/v1612714524/4300_dpnres.jpg`,
    `/v1612729933/2301_ca6ium.jpg`,

    `/v1612299600/600_rtwikm.jpg`,
    `/v1612299605/601_yqwdi0.jpg`,
    `/v1612299618/602_ulhili.jpg`,
    `/v1612299615/603_parctw.jpg`,
    `/v1612559261/604_k005eu.jpg`,
    `/v1612714524/3601_idjs1w.jpg`,
    `/v1612714519/3501_mpgbjy.jpg`,
    `/v1612299579/1400_g8iinl.jpg`,
  ],

  [
    `/v1612716976/4100_uj2o8y.mp4`,
    `/v1612560612/1900_amlx6q.jpg`,
    `/v1612717080/4400_mlr7zd.jpg`,
    `/v1612730068/4500_dbkxik.jpg`,

    `/v1612562962/2200_sjelni.jpg`,
    `/v1612562963/2201_dvugb7.jpg`,
    `/v1612562962/2203_itufdw.jpg`,
    `/v1612730185/3202_wyniay.jpg`,
    `/v1612717237/3200_lbqeoj.mp4`,

    `/v1612299586/1300_apnfuy.jpg`,
    `/v1612299579/1301_w7ouff.jpg`,
    `/v1612299586/1302_xpzfgm.jpg`,
    `/v1612299592/1303_owmq65.jpg`,
    `/v1612299587/1304_mioior.jpg`,
    `/v1612299608/1305_hog87w.jpg`,

    `/v1612717853/2700_pqtpkz.jpg`,
  ],
];

function preloadImage(url) {
  return new Promise((resolve) => {
    const image = document.createElement("img");

    image.setAttribute("src", url);

    image.addEventListener("load", function () {
      resolve(url);
    });
  });

  // image.addEventListener("error", err);
}

function preloadVideo(url) {
  return new Promise((resolve) => {
    const video = document.createElement("video");

    video.setAttribute("src", url);
    video.setAttribute("preload", true);

    video.addEventListener("canplay", function () {
      resolve(url);
    });
  });
}

const cloudinaryImageUrl = `https://res.cloudinary.com/dpd64vzpx/image/upload`;
const cloudinaryVideoUrl = `https://res.cloudinary.com/dpd64vzpx/video/upload`;

function getPreloadUrlsForImage(url) {
  const urls = [
    // `${cloudinaryImageUrl}/w_20${url}`,
    // `${cloudinaryImageUrl}/w_300,q_auto${url}`,
    // `${cloudinaryImageUrl}/w_600,q_auto${url}`,
    // `${cloudinaryImageUrl}/w_900,q_auto${url}`,
    `${cloudinaryImageUrl}/w_1800,q_auto${url}`,
  ];

  return urls;
}

export function preloadPage(page = 0) {
  const urlsToLoad = pageUrls[page] || [];

  return Promise.all(urlsToLoad.map((url) => preload(url)));
}

function preload(url) {
  if (url.endsWith(".jpg") || url.endsWith(".png")) {
    const imgUrls = getPreloadUrlsForImage(url);
    return Promise.all(imgUrls.map((url) => preloadImage(url)));
  }

  if (url.endsWith(".mp4")) {
    url = `${cloudinaryVideoUrl}${url}`;
    return url;
  }
}

function Work({ sections = [], startPage = 0 }) {
  const [page, setPage] = React.useState(1);
  const [loadedPage, setLoadedPage] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const pageSize = 5;

  React.useEffect(() => {
    setLoading(true);

    preloadPage(page).then((loadedUrls) => {
      setLoadedPage(page);
      setLoading(false);
    });
  }, [page]);

  const startIndex = 0;
  const endIndex = pageSize * loadedPage;

  const screens = sections.slice(startIndex, endIndex);

  function incrementPage() {
    setPage((p) => p + 1);
  }

  const isInitialLoad = loading && page === 1;
  const isLoadingMore = loading && page > 1;

  return (
    <div className="relative">
      <div className="relative">
        {React.Children.map(screens, (screen) => screen)}
        <div
          className={`absolute h-full min-h-screen top-0 left-0 right-0 bg-white flex justify-center ${
            isInitialLoad
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <p className="text-2xl" style={{ marginTop: "30%" }}>
            Loading...
          </p>
        </div>
      </div>
      <div
        style={{ zIndex: 100 }}
        className={`fixed bottom-0 left-0 right-0 h-24 flex items-center justify-center bg-white ${
          isLoadingMore
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none transition-opacity delay-1000"
        }`}
      >
        <p className="text-2xl">Loading...</p>
      </div>
      {screens.length < sections.length && (
        <div
          className="h-24 flex justify-center bg-white"
          style={{ zIndex: 90 }}
        >
          <button
            className="py-2 px-4 focus:outline-none"
            onClick={incrementPage}
          >
            <p className="text-2xl">See More</p>
          </button>
        </div>
      )}
    </div>
  );
}

export { Work };
