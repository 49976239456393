import * as React from "react";

function Info() {
  return (
    <div className="mx-auto max-w-screen-lg">
      <div className="pb-20 my-12 px-4 text-2xl">
        <div>
          <p>2018-Present</p>

          <p>PAT McGRATH LABS, New York</p>
          <p className="underline">Senior Art Director</p>

          <p className="">Creative Director: Pat McGrath</p>
        </div>

        <div className="mt-16">
          <p>2016-2018</p>

          <p>CALVIN KLEIN, New York</p>

          <p className="underline">
            Senior Director, Brand Creative and Design
          </p>

          <p className="">
            Creative Directors: Raf Simons, Pieter Mulier, Peter Saville
          </p>
        </div>

        <div className="mt-16">
          <p>2011-2016</p>

          <p>KiDS CREATIVE, New York</p>

          <p className="underline">Senior Art Director</p>

          <p className="">Creative Director: Pascal Dangin</p>
        </div>

        <div className="mt-16">
          <p>2009-2011</p>
          <p>V MAGAZINE / VMAN / VISIONAIRE / V AGENCY, New York</p>
          <p className="underline">Graphic Designer</p>
          <p className="">Creative Director: Stephen Gan</p>
        </div>

        <div className="mt-16">
          <p>2010</p>
          <p>Bachelor of Design (BDes), York University</p>
        </div>
      </div>
    </div>
  );
}

export { Info };
