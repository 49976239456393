import * as React from "react";
import { Image } from "./Image";

function Gallery({ screens = [], showHint = false }) {
  const [activeIndex, setActiveIndex] = React.useState(0);

  function onGalleryClick() {
    setActiveIndex((activeIndex) => {
      if (activeIndex === screens.length - 1) {
        return 0;
      }

      return activeIndex + 1;
    });
  }

  return (
    <div>
      <div className="relative">
        <Image src={screens[0]} loading="eager" />

        <div className="absolute inset-0">
          {screens.map((screen, index) => {
            const isSelected = index === activeIndex;

            return (
              <div
                key={screen}
                onClick={onGalleryClick}
                className={`absolute inset-0 focus:outline-none ${
                  isSelected ? "z-10" : "z-0"
                }`}
              >
                <Image src={screen} />
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={`${
          showHint ? "mb-4" : ""
        } flex items-center justify-center  py-1 lg:pb-4`}
      >
        {showHint && (
          <div className="flex justify-center mr-4">
            <span className="text-center text-xs font-lg">
              Click Images to Scroll
            </span>
          </div>
        )}
        <div className="flex space-x-3 justify-center">
          {screens.map((s, index) => {
            const isSelected = index === activeIndex;
            return (
              <button
                key={s}
                onClick={(e) => {
                  setActiveIndex(index);
                }}
                className="focus:outline-none p-2"
              >
                <div
                  className={`h-3 w-3 lg:w-3 lg:h-3 rounded-full ${
                    isSelected
                      ? "bg-gray-300 border-white border-2"
                      : "bg-gray-900 border-2 border-gray-300"
                  }`}
                />
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export { Gallery };
