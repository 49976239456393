import * as React from "react";
import { Gallery } from "./Gallery";
import { Gif, Image } from "./Image";
import { Video, VideoLink } from "./Video";

const cloudinaryVideoUrl = `https://res.cloudinary.com/dpd64vzpx/video/upload`;

const wangUrls = [
  `/v1612299600/600_rtwikm.jpg`,
  `/v1612299605/601_yqwdi0.jpg`,
  `/v1612299618/602_ulhili.jpg`,
  `/v1612299615/603_parctw.jpg`,
  `/v1612559261/604_k005eu.jpg`,
];

const balenciaUrls = [
  `/v1612299583/1100_euzhcn.jpg`,
  `/v1612299583/1101_yfljbj.jpg`,
  `/v1612299577/1102_pt7or2.jpg`,
  `/v1612299582/1103_ybxe2j.jpg`,
  `/v1612559103/1104_pensjw.jpg`,
  `/v1612729739/1105_xikfha.jpg`,
];

const calvinUrls = [
  `/v1612299586/1300_apnfuy.jpg`,
  `/v1612299579/1301_w7ouff.jpg`,
  `/v1612299586/1302_xpzfgm.jpg`,
  `/v1612299592/1303_owmq65.jpg`,
  `/v1612299587/1304_mioior.jpg`,
  `/v1612299608/1305_hog87w.jpg`,
];

const twoThousands = [
  `/v1612562623/2000_ljj5d0.jpg`,
  `/v1612562624/2001_hochwx.jpg`,
  `/v1612562623/2002_sf4e1t.jpg`,
];

const twentyOne = [
  `/v1612562845/2101_es8szh.jpg`,
  `/v1612562844/2100_qpppbv.jpg`,
  `/v1612562846/2102_qzytas.jpg`,
];

const twentyTwo = [
  `/v1612562962/2200_sjelni.jpg`,
  `/v1612562963/2201_dvugb7.jpg`,
  `/v1612562962/2203_itufdw.jpg`,
];

const sections = [
  // SECTION 1 -

  <Section>
    <Image src={`/v1612210891/100_j90gox.jpg`} />
  </Section>,

  <Section name="Blue Eyes">
    <div className="relative">
      <Image
        className="mx-auto max-h-screen-lg"
        src={`/v1612299687/401_k25zrv.jpg`}
        loading="eager"
      />

      <div className="absolute flex items-center w-1/2 top-0 bottom-0 left-0">
        <div
          className="absolute h-1/2 right-0"
          style={{ transform: "translate(-40%, 24%)", maxWidth: "384px" }}
        >
          <Video className="h-full" src={`/v1612210983/400_eqmbeu.mp4`} />
          <VideoLink
            href="https://www.instagram.com/p/BwPe6IFlTQR"
            bg={`rgb(6, 0,  255)`}
          />
        </div>
      </div>
    </div>
  </Section>,

  <Section>
    <Image
      src="/v1612210897/200_nv6zos.jpg"
      looading="eager"
      alt="Blue Jeans"
    />
  </Section>,

  <Section>
    <div className="relative flex  mt-2 md:mt-4">
      <Image src={`/v1612559359/1701_ir55ad.jpg`} alt="Divine Rose" />

      <div
        className="absolute top-0 transform"
        style={{ width: "40%", transform: `translate(65%, 30%)` }}
      >
        <Video src={`/v1612210961/500_a2rbe6.mp4`} />
        <VideoLink
          href="https://www.instagram.com/p/B5A6ujLloig/"
          bg={"rgb(255,197,245)"}
          color="black"
        />
      </div>
    </div>

    <div className="flex relative">
      <Image src="/v1612560023/1702_f7k0z5.jpg" alt="Naomi" />

      <div className="absolute flex justify-center items-center top-0 right-0 left-0 bottom-0">
        <div className="w-1/4" style={{ width: "32%", maxWidth: "450px" }}>
          <Video src={`/v1612560062/1702_vkvhbs.mp4`} />
          <VideoLink
            href="https://www.instagram.com/p/CAvDKINF1hj/"
            bg={"rgb(255,197,245)"}
            color="black"
          />
        </div>
      </div>
    </div>
  </Section>,

  <Section>
    <Gif
      className="max-w-screen-xl"
      src={`/v1612299614/1200_bou5ps.gif`}
      loading="eager"
    />
  </Section>,

  // SECTION 2 -

  <Section>
    <Gallery screens={twoThousands} showHint />
  </Section>,

  <Section>
    <Gallery screens={balenciaUrls} />
  </Section>,

  <Section>
    <div className="flex mb-4 md:mb-0">
      <div className="relative mx-auto">
        <Image src={`/v1612299601/700_yr9rup.jpg`} />

        <div
          className="absolute h-1/2 w-1/3 bottom-0 right-0"
          style={{ transform: `translate(-7.5%, -15%)` }}
        >
          <Video className="h-full" src={`/v1612299634/700_deyygg.mp4`} />
          <VideoLink
            href="https://www.instagram.com/p/CFXU2kspHly/?igshid=pzlv6rk9l0m4"
            bg={"rgb(221,165,244)"}
            color="black"
          />
        </div>
      </div>
    </div>
  </Section>,

  <Section>
    <div className="relative flex justify-center items-center">
      <Image src={`/v1612729546/301_wi6qhr.jpg`} alt="Popcorn" />

      <div
        className="absolute transform"
        style={{
          width: "20%",
          transform: "translate(23%,0)",
          minWidth: `100px`,
        }}
      >
        <Video className="w-full" src={`/v1612557730/300_id6bjt.mp4`} />
        <VideoLink href="https://www.instagram.com/p/BfGrS5ZgfVe/" />
      </div>
    </div>
  </Section>,

  <OrangeVideo />,

  // SECTION 3 -

  <Section>
    <div className="mt-4" />
    <div className="flex">
      <div className="relative mx-auto">
        <Image src={`/v1612560735/1800_hahilo.jpg`} alt="Yellow" />

        <div className="absolute top-0 right-0 left-0">
          <div
            className=""
            style={{
              width: "60%",
              transform: `translate(5%, 55%)`,
            }}
          >
            <Video src={`/v1612560754/1800_rqusrv.mp4`} />
            <VideoLink
              href={`${cloudinaryVideoUrl}/v1612560754/1800_rqusrv.mp4`}
            />
          </div>
        </div>
      </div>
    </div>
  </Section>,

  <Section>
    <div className="relative mx-auto mt-2">
      <div style={{ transform: `translateY(-6%)` }}>
        <Video
          src={`/v1612561823/3000_t1wapf.mp4`}
          className="max-h-screen-lg mx-auto transform scale-75"
        />
      </div>
      <div className="absolute inset-0 opacity-100">
        <Image src={`/v1612561731/3000_k5rlzq.png`} />
      </div>
    </div>
  </Section>,

  // <Section>
  //   <div className="flex justify-center mb-6 mt-4 md:my-0">
  //     <div className="relative mx-auto">
  //       <Image src={`/v1612729660/2802_vcxnpn.jpg`} alt="Red" />
  //       <div className="absolute right-0 top-0 bottom-0 w-1/2">
  //         <div className="absolute bottom-0 right-0 w-1/2">
  //           <Video
  //             src={`/v1612714637/2800_xothsd.mp4`}
  //             style={{ width: "100%" }}
  //           />
  //           <VideoLink
  //             href="https://www.instagram.com/p/BYs8BPaAZ4Y/"
  //             bg={"rgb(234,8,8)"}
  //             color="black"
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </Section>,

  <Section>
    <div className="relative flex items-center md:mt-12 mt-8">
      <Image src={`/v1612714521/3901_vshzul.jpg`} alt="Underwear" />

      {/* <div className="flex items-center justify-center absolute inset-0">
        <div
          style={{
            height: "54%",
            transform: `translate(-11%, 22%)`,
            maxWidth: "277px",
            minWidth: "95px",
          }}
        >
          <Video className="h-full" src={`/v1612715379/3900_jgvjae.mp4`} />
          <VideoLink
            bg="rgb(12,84,45)"
            href="https://www.instagram.com/p/BlqPAcoAk18/"
          />
        </div>
      </div> */}
    </div>
  </Section>,

  <Section>
    <div className="mx-auto w-3/4 mb-4 mt-8 md:w-1/2 md:my-16">
      <Video src={`/v1612715993/2900_ay6ehd.mp4`} />
      <VideoLink
        href="https://www.youtube.com/watch?v=EPiDroK1M2o&t=2s&ab_channel=AlexanderMcQueen"
        bg="rgb(255, 252, 5)"
        color="black"
      />
    </div>
  </Section>,

  // SECTION 4 -

  <Section>
    <Image
      className="max-w-screen-xl w-full"
      src={`/v1612714522/4200_vylezo.jpg`}
    />
  </Section>,

  <Section>
    <Gallery screens={twentyOne} />
  </Section>,

  <Section>
    <Image src={`/v1612302457/800_qftxym.jpg`} />
  </Section>,

  <Section>
    <div className="relative flex my-4 md:my-14">
      <Image src={`/v1612714517/1003_eehq7g.jpg`} alt="Polaroid Strip" />
      <div className="absolute flex items-center right-0 top-0 left-0 bottom-0">
        <div style={{ width: "17.5%", transform: `translate(177%, 0)` }}>
          <Video src={`/v1612561191/1001_e949pb.mp4`} />

          <VideoLink
            href="http://instagram.com/realpatmcgrath"
            bg="rgb(181, 190, 243)"
            color="black"
          />
        </div>
      </div>
    </div>
  </Section>,

  <Section>
    <Image
      className="max-w-screen-xl w-full"
      src={`/v1612714524/4300_dpnres.jpg`}
    />
  </Section>,

  // SECTION 5 -

  <Section>
    <Image
      className="max-w-screen-xl w-full"
      src={`/v1612729933/2301_ca6ium.jpg`}
      alt="KiDS"
    />
    <div className="my-0 lg:my-12" />
  </Section>,

  <Section>
    <Gallery screens={wangUrls} />
  </Section>,

  <Section>
    <Image
      className="max-w-screen-xl w-full"
      src={`/v1612714524/3601_idjs1w.jpg`}
    />
  </Section>,

  <Section>
    <Image
      className="max-w-screen-xl w-full"
      src={`/v1612714519/3501_mpgbjy.jpg`}
    />
  </Section>,

  <Section>
    <Image src={`/v1612299579/1400_g8iinl.jpg`} />
  </Section>,

  // SECTION 6 -

  <Section>
    <div className="mx-auto w-full md:w-3/4 mt-12 mb-8 md:mb-12 px-4 md:px-0 md:mt-20">
      <Video
        className="mx-auto"
        src={`/v1612716976/4100_uj2o8y.mp4`}
        videoLink={{
          href:
            "https://www.youtube.com/watch?v=EPiDroK1M2o&t=2s&ab_channel=AlexanderMcQueen",
        }}
      />
      <VideoLink href={`${cloudinaryVideoUrl}/v1612716976/4100_uj2o8y.mp4`} />
    </div>
  </Section>,

  <Section>
    <Image src={`/v1612560612/1900_amlx6q.jpg`} />
  </Section>,

  <Section>
    <Image
      className="mx-auto max-w-screen-lg w-full"
      src={`/v1612717080/4400_mlr7zd.jpg`}
    />
  </Section>,

  <Section>
    <div className="my-4 md:my-12" />
    <Image className="mx-auto" src={`/v1612730068/4500_dbkxik.jpg`} />
  </Section>,

  <Section>
    <Gallery screens={twentyTwo} />
  </Section>,

  // SECTION 7 -

  <Section>
    <div className="relative mx-auto mb-2 md:mb-0">
      <Image
        style={{ width: "100%", maxHeight: "100%" }}
        src={`/v1612730185/3202_wyniay.jpg`}
        alt="Times Square"
      />

      <div className="absolute inset-0 flex items-center justify-center">
        <div style={{ width: "50%", margin: "0 auto" }}>
          <Video src={`/v1612717237/3200_lbqeoj.mp4`} />
          <VideoLink
            bg="rgb(235,7,7)"
            color="black"
            href="https://www.youtube.com/watch?v=V_MOomsh7Rk&ab_channel=L'ADN"
          />
        </div>
      </div>
    </div>
  </Section>,

  <Section>
    <Gallery screens={calvinUrls} />
  </Section>,

  <Section>
    <div className="mt-4" />
    <Image src={`/v1612717853/2700_pqtpkz.jpg`} />
  </Section>,
];

const largeScreen = "(min-width: 476px)";

function OrangeVideo() {
  const transform = useMedia(
    [largeScreen],
    [`translate(0, 8%)`],
    `translate(0, 25%)`
  );

  return (
    <Section>
      <div className="flex max-w-screen-xl w-full mx-auto my-4 lg:mb-12">
        <div className="w-1/3 z-10">
          <div>
            <Video src="/v1612729165/3402_xfagxj.mp4" />
            <div className="flex justify-center md:justify-start">
              <div>
                <VideoLink
                  bg="rgb(255, 153, 62)"
                  href="https://www.instagram.com/p/BfWA7Y5A2kX/"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/3 z-0">
          <div className="relative mx-auto">
            <div
              className="mx-auto"
              style={{ width: "90%", transform: `translate(0, 14%)` }}
            >
              <Video src="/v1612718162/3400_cf163z.mp4" />
            </div>
            <div className="absolute inset-0">
              <Image
                className="w-full"
                style={{ transform }}
                src="/v1612728445/3402_ahmorx.png"
                alt="Orange"
              />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

function Section({ children }) {
  return <>{children}</>;
}

export { sections };

// Hook
function useMedia(queries, values, defaultValue) {
  // Array containing a media query list for each query
  const mediaQueryLists = queries.map((q) => window.matchMedia(q));

  // Function that gets value based on matching media query
  const getValue = () => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    // Return related value or defaultValue if none
    return typeof values[index] !== "undefined" ? values[index] : defaultValue;
  };

  // State and setter for matched value
  const [value, setValue] = React.useState(getValue);

  React.useEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = () => setValue(getValue);
      // Set a listener for each media query with above handler as callback.
      mediaQueryLists.forEach((mql) => mql.addListener(handler));
      // Remove listeners on cleanup
      return () =>
        mediaQueryLists.forEach((mql) => mql.removeListener(handler));
    },
    [] // Empty array ensures effect is only run on mount and unmount
  );

  return value;
}
